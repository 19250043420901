.grid-container {
  margin: auto;
  margin-top: 2rem;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.title-container {
  margin: auto;
  margin-top: 4rem;
  margin-bottom: 2rem;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.grid-item {
  flex: 1 1 calc(30% - 1rem);
  min-height: 280px;
  margin: 0% !important;
  margin-right: 2% !important;
  margin-top: 1% !important;
  margin-bottom: 1% !important;
  background-size: cover;
  background-position: center;
  position: relative;
}

.grid-item-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  background-color: #222222;
  color: white;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.grid-item-center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  color: white;
  text-align: center;
}

.grid-text-red{
  color: #ff2c55;
  font-size: 22px;
}

.grid-text{
  color: white;
  font-weight: 600;
  font-size: 22px;
}

.grid-title{
  color: black;
  font-weight: 600;
  font-size: 35px;
}

a:link {
      text-decoration: none;
}

a:visited {
      text-decoration: none;
}

a:hover {
      text-decoration: none;
}

a:active {
      text-decoration: none;
}

.news-title{
  text-align: left;
  color: black;
  font-size: 40px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0px;
}

.overflow-x {
  overflow-x: auto;
}

.news-columns-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  border-bottom: 1px solid gray;
  margin: auto;
}

.column {
  flex: 1 !important;
  text-align: left;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.icon {
  width: 30px;
  height: 30px;
}

.news-table-header{
  font-weight: 700;
  font-size: 24px;
  color: rgba(172, 172, 172, 0.97);
}

.news-table-cell{
  font-weight: 700;
  font-size: 20px;
  color: rgba(172, 172, 172, 0.97);
}

.news-table-text{
  font-weight: 700;
  font-size: 20px;
  color: #222222;
}

.news-table-icon{
  width: 40px;
  height: 40px;
  color: black;
}

.news-table-icon-column{
  text-align: right;
}

@media (max-width: 1300px) {

  .grid-item {
    flex: 1 1 calc(90% - 1rem);
    min-height: 250px;
    margin: 1%;
    background-size: cover;
    background-position: center;
    position: relative;
  }

}