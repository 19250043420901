.grid-container {
  margin: auto;
  margin-top: 2rem;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.grid-item {
  flex: 1 1 calc(30% - 1rem);
  min-height: 280px;
  margin: 1%;
  background-size: cover;
  background-position: center;
  position: relative;
}

.grid-item-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  background-color: #222222;
  color: white;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.grid-text-red{
  color: #ff2c55;
  font-size: 22px;
}

.grid-text{
  color: white;
  font-weight: 600;
  font-size: 22px;
}

@media (max-width: 1300px) {

  .grid-item {
    flex: 1 1 calc(90% - 1rem);
    min-height: 250px;
    margin: 1%;
    background-size: cover;
    background-position: center;
    position: relative;
  }


}
