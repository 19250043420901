.container-market {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 2rem;
}

.button-buy{
  background-color: #FF2C55;
  color: white;
  box-shadow: 0 6px 8px #22222266;
  margin-right: 32px;
  width: 200px;
}

.button-buy:hover{
  opacity: 0.7;
}

.button-sell{
  background-color: white;
  color: #FF2C55;
  box-shadow: 0 6px 8px #22222266;
  width: 200px;

}

.button-sell:hover{
  opacity: 0.7;
}

@media (max-width: 1300px) {
  .button-buy{  
    margin-right: 0px;
  }

}

