.info-box {
  width: 80%;
  background-color: #11111111;
  margin: 0 auto;
}

.info-box-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 50px;
  margin-top: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.premium-investor {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 40px;
  padding-left: 6rem;
  padding-right: 6rem;
  background-color: black;
  color: white;
  border-radius: 2px;
  cursor:pointer;
  box-shadow: 0 6px 8px #22222266;
  margin-top: -0.8rem;
}

.info-box-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
  margin-left: 6rem;
  margin-right: 6rem;
}

.white-box,
.black-box,
.red-box {
  width: 18%;
  background-color: white;
  text-align: center;
  padding: 1rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
  box-sizing: border-box;
  border-radius: 2px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 50px;
  box-shadow: 0 6px 8px #22222266;
}

.black-box {
  background-color: black;
  color: white;
}

.red-box {
  background-color: #FF2C55;
  color: white;
}

.box-text {
  font-size: 32px !important;
  font-weight: 700;
}

.investor-label{
  font-size: 1rem;
  color: #ff2c55;
  font-weight: 600;
}

.investor-label-black{
  font-size: 1rem;
  color: black;
  font-weight: 600;
}

@media (max-width: 1300px) {
  .info-box-top {
    flex-direction: column;
    align-items: center;
  }

  .info-box-bottom {
    flex-direction: column;
    margin-top: 1rem;
    padding-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .white-box,
  .black-box,
  .red-box {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .premium-investor {
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 3rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
  
  .name {
    font-size: 30px;
  }

}

