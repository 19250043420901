.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 2rem;
}

.logo {
  max-width: 180px;
  z-index: 999;
}

.center-image {
  max-width: 380px;
  margin-right: 100px;
  z-index: 999;
}

.hamburger {
  cursor: pointer;
  width: 3.5rem;
}

.close {
  cursor: pointer;
  font-size: 3.5rem;
}

.cursor-pointer{
  cursor: pointer;
}

.fullscreen-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 900;
}

.menu-line{
  background-color: white;
  height: 1px;
}

.fullscreen-menu nav ul{
  width: 100%;
  left: 0;
  top: 35%;
  position: absolute;
  list-style-type: none;
  padding: 0;
  text-align: center;
  margin-top: 0;
}

.fullscreen-menu a{
  text-decoration: none;
  color: white;
}

.fullscreen-menu nav ul li {
  font-size: 80px;
  color: white;
  cursor: pointer;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 10%;
}


.menu-item {
  color: white;
}

.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
}

.menu-footer-row{
  position: absolute;
  bottom: 15%;
  left: 10%;
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.logo-a{
  z-index: 999;
}

.center-image-a{
  z-index: 999;
}



@media (max-width: 768px) {

  .center-image {
    max-width: 220px;
    margin-bottom: 1rem;
    margin-right: 0px;
    margin-top: 20px;
  }

  .logo-a{
    display: none;
  }

  .logo{
    display: none;
  }

  .menu-footer-row{
    display: block;
  }

  .fullscreen-menu nav ul li {
    font-size: 30px;
  }


}
