.footer {
  background-color: #ff2c55;
  color: white;
  padding: 2rem;
  padding-left: 20%;
  padding-right: 20%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 6rem;
  bottom: 0;
  left: 0;
}

.footer-portfolio {
  color: white;
  padding: 2rem;
  padding-left: 20%;
  padding-right: 20%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 6rem;
  bottom: 0;
  left: 0;
  background-color: #222222;
}

.footer-startup {
  background-color: #22222210;
  color: white;
  padding: 2rem;
  padding-left: 20%;
  padding-right: 20%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 6rem;
  bottom: 0;
  left: 0;
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.footer-col {
  flex: 1;
}

.footer-title-black {
  text-align: left;
  color: black;
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 50px;
}

.footer-title-white {
  text-align: left;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  display: block;
}

.footer-title-white-bold {
  text-align: left;
  color: white;
  font-style: normal;
  margin-top: 1rem;
  font-weight: 700;
  font-size: 45px;
  line-height: 50px;
  display: block;
}

.footer-subtitle-black {
  text-align: left;
  color: black;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 50px;
}

.footer-text-black {
  text-align: left;
  color: black;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 50px;
}

.footer-title-red {
  text-align: left;
  color: #ff2c55;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
}

.footer-text-white {
  text-align: left;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 50px;
}

.footer-title-black-startup {
  text-align: left;
  color: black;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 50px;
}

.footer-title-red-startup {
  text-align: left;
  color: #ff2c55;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 50px;
  display: block;
}

.footer-title-red-bold-startup {
  text-align: left;
  color: #ff2c55;
  font-style: normal;
  margin-top: 1rem;
  font-weight: 700;
  font-size: 45px;
  line-height: 50px;
  display: block;
}

.footer-subtitle-black-startup {
  text-align: left;
  color: black;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 50px;
}

.footer-text-black-startup {
  text-align: left;
  color: black;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 50px;
}

.footer-title-white-portfolio {
  text-align: left;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 50px;
}

.footer-title-red-portfolio {
  text-align: left;
  color: #ff2c55;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 50px;
  display: block;
}

.footer-title-red-bold-portfolio {
  text-align: left;
  color: #ff2c55;
  font-style: normal;
  margin-top: 1rem;
  font-weight: 700;
  font-size: 45px;
  line-height: 50px;
  display: block;
}

.footer-subtitle-white-portfolio {
  text-align: left;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 50px;
}

.footer-text-white-portfolio {
  text-align: left;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 50px;
}

.footer-icon{
  color: black;
  width: 25px;
  margin-bottom: -0.1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.footer-icon-white{
  color: white;
  width: 25px;
  margin-bottom: -0.1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.footer-logo {
  text-align: right;
}

@media (max-width: 768px) {

  .footer {
    padding-left: 5%;
    padding-right: 5%;

  }

  .footer-portfolio {
    padding-left: 5%;
    padding-right: 5%;

  }

  .footer-startup {
    padding-left: 5%;
    padding-right: 5%;

  }

  .footer-logo {
    display: none;
  }

  .footer-title-red {
    font-size: 26px;
  }

  .footer-title-black {
    font-weight: 500;
    font-size: 26px;
  }

  .footer-title-white {
    font-weight: 600;
    margin-top: 0;
    font-size: 26px;
  }

  .footer-title-white-bold {
    font-weight: 700;
    margin-top: 0;
    font-size: 28px;
  }

  .footer-subtitle-black {
    font-weight: 500;
    font-size: 22px;
  }

  .footer-text-black {
    font-weight: 500;
    font-size: 30px;
  }

}



