.container {
  width: 80%;
  margin: 0 auto;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.team-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  margin: auto;
  height: 60px;
  white-space: normal;
}

.documents-row{
  display: flex;
  width: 30%;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  height: 60px;
  white-space: normal;
}

.arrow-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin: auto;
  width: 80%;
}

.text-row {
  margin-bottom: 1rem;
  text-align: left;
  color: black;
  font-size: 18px;
}

.startup-title{
  text-align: left;
  color: black;
  font-size: 22px;
  font-weight: 700;
}

.round-corners{
  border-radius: 1.5rem !important;
}

.long-text-box {
  background-color: black;
  color: white;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: left;
}

.startup-icon{
  background-color: black;
  margin: 0.5rem;
  margin-top: 2.5rem;
  padding: 0.5rem;
  box-shadow: 0 6px 8px #22222266;
  color: white;
  font-size: 30px;
}

.box-width{
  width: 18%;
}


.icons-column{
  text-align: right;
}

.arrow-link{
  color: black;
  align-self: flex-start;
}

.team-table-icon-column{
  text-align: right;
}

.team-name{
  flex: 5 !important;
  display: flex;
  align-items: center;
  font-size: 18px;
  height: 50px;
  padding-left: 12px !important;
  padding-right: 12px !important;
  box-shadow: 0 6px 8px #22222266;
  background-color: white;
  margin-right: 5%;
  padding-left: 12px !important;
  padding-right: 12px !important;
  box-shadow: 0 6px 8px #22222266;
}

.team-role{
  flex: 5 !important;
  display: flex;
  align-items: center;
  font-size: 18px;
  background-color: black;
  color: white;
  height: 50px;
  padding-left: 12px !important;
  padding-right: 12px !important;
  box-shadow: 0 6px 8px #22222266;
}

.team-linkedin{
  flex: 1 !important;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 18px;
  background-color: transparent;
  color: white;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.team-icon{
  box-shadow: 0 6px 8px #22222266;
  background-color: black;
  margin-right: -10px;
  padding: 0.5rem;
  box-shadow: 0 6px 8px #22222266;
  color: white;
  font-size: 30px;
  text-align: right;
}

.team-black{
  flex: 1 !important;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 18px;
  background-color: black;
  color: white;
  margin-right: 5%;
  padding-left: 12px !important;
  padding-right: 12px !important;
  box-shadow: 0 6px 8px #22222266;
}

.team-black-last{
  flex: 1 !important;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 18px;
  background-color: black;
  color: white;
  padding-left: 12px !important;
  padding-right: 12px !important;
  box-shadow: 0 6px 8px #22222266;
}

.team-red{
  flex: 1 !important;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 18px;
  background-color: #FF2C55;
  color: white;
  margin-right: 5%;
  padding-left: 12px !important;
  padding-right: 12px !important;
  box-shadow: 0 6px 8px #22222266;
}

.team-white{
  flex: 1 !important;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 18px;
  background-color: white;
  color: black;
  margin-right: 5%;
  padding-left: 12px !important;
  padding-right: 12px !important;
  box-shadow: 0 6px 8px #22222266;
}

.scroll-container {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}




@media (max-width: 1300px) {

  .row {
    flex-direction: column;
  } 

  .documents-row{
    width: 100%;
  }

  .white-box,
  .black-box,
  .red-box {
    width: 100%;
  }

  .icons-column{
    display: inherit;
  }


}
