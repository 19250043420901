.image-slider {
  width: 80%;
  margin: 0 auto;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.slider {
  position: relative;
}

.slider img {
  width: 100%;
  display: block;
}

.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  font-size: 2rem;
  color: white;
  transform: translateY(-50%);
  z-index: 1;
}

.slick-prev {
  left: 1rem;
}

.slick-next {
  right: 1rem;
}

.slick-dots {
  position: absolute;
  bottom: 15px;
  list-style-type: none;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
}

.slick-dots li {
  margin-right: 20px;
  margin-bottom: 12px;
}

.custom-dot {
  width: 12px;
  height: 12px;
  background-color: transparent;
  border: 1px solid white;
}

.slick-dots .slick-active div {
  background-color: red;
  border: none;
  width: 13px;
  height: 13px;
}

@media (max-width: 768px) {
  .image-slider {
    width: 80%;
  }
}
