.three-columns-container {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
}

.column-padding {
  width: 30%;
  padding: 1rem;
  position: relative;
}

.column-padding-levels {
  padding: 1rem;
  position: relative;
}

/*.column:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #22222266;
}*/

.white-label,
.black-label,
.red-label {
  background-color: white;
  text-align: center;
  margin-bottom: 2rem;
  box-sizing: border-box;
  border-radius: 2px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 50px;
  box-shadow: 0 6px 8px #22222266;
}

.black-label {
  background-color: black;
  color: white;
}

.red-label {
  background-color: #FF2C55;
  color: white;
}


ul {
  list-style-type: none;
  padding: 0;
  margin-top: 10rem;
}

li {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 1rem;
}

li img {
  width: 20px;
  height: 20px;
  margin-right: 
}

li img {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

.small-red-text {
  color: #FF2C55;
  font-size: 12px;
  font-weight: 700;
  margin-top: 2rem;
  text-align: right;
}

.levels-title{
  text-align: left;
  color: black;
  font-size: 22px;
  font-weight: 700;
}

.arrow-red{
  width: 25px;
  height: 25px;
  color: #ff2c55;
}

.levels-top-content{
  height: 550px;
}

@media (max-width: 1300px) {
  .three-columns-container {
    flex-direction: column;
  }

  .column {
    width: calc(100% - 2rem);
    margin-bottom: 1rem;
  }

  .column:not(:last-child)::after {
    display: none;
  }
}
