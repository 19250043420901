.login-title{
  font-size: 30px;
  color: black;
  font-weight: 700;
  text-align: center;
  margin-bottom: 6rem;
}

.form-label{
  font-size: 22px;
  margin-bottom: 1rem;
  text-align: left;
  font-weight: 600;
}

.login-container{
  margin: auto;
  margin-top: 10rem;
  width: 40%;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-bottom: 2rem;
}

.radio-group {
  text-align: left;
  margin-bottom: 20px;
  margin-bottom: 2rem;
}

.form-input {
  border: none;
  border-bottom: 1px solid #000;
  outline: none;
  text-align: left;
  font-size: 24px;
}

.form-input-radio {
  border: none;
  outline: none;
  text-align: left;
  font-size: 24px;
  margin-right: 12px;
  margin-left: 4px;
}

input[type='radio'] {
  accent-color: #ff2c55;
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}

.login-text{
  color: black;
  font-size: 16px;
  text-decoration: underline;
  text-align: center;
}

.form-feedback{
  color: #fff;
}

.form-feedback-box{
  background-color: #ff2c55;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 64px;
  padding-right: 64px;
  box-shadow: 0 6px 8px #22222266;
}

button {
  /* Estilos opcionales para el botón de inicio de sesión */
  background-color: transparent;
  border: none;
  color: #ff2c55;
  padding: 1rem 2rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 1rem;
  cursor: pointer;
  border-radius: 2px;
}

@media (max-width: 768px) {

  .login-container{
    margin: auto;
    margin-top: 10rem;
    width: 80%;
  }

  .form-feedback-box{
    background-color: #ff2c55;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 32px;
    padding-right: 32px;
    box-shadow: 0 6px 8px #22222266;
  }


}